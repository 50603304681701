<template>
  <div class="warp">
    <div class="search">
      <div class="ser">
        <!-- <div class="cser">
            <div :class="ac==1?'active':''" @click="activv(1)">
                <span>商品</span>
            </div>
            <div @click="activv(2)" :class="ac==2?'active':''">
                <span>供应商</span>
            </div>
        </div> -->
        <div class="mb">
          <svg-icon icon-class="sousuo" class="cx"></svg-icon>
          <el-input placeholder="请输入商品名称/供应商名称" v-model="params.keyword" class="sInput">
            <el-button slot="append" size="small" @click="searchGoods">搜索</el-button>
          </el-input>
        </div>
      </div>
      <div class="searchBottom">
        <div class="flBox">
          <div class="menu">商品分类</div>
          <ul>
            <li v-for="item in categoryListLevel1" @mouseover="hov(item,'0')" @click="go(item)">{{ item.name }}</li>
          </ul>
          <ul style="left: 200px" v-show="child.length>0">
            <li v-for="item in child" @mouseover="hov(item,'1')" @click="go(item)">{{ item.name }}</li>
          </ul>
          <ul style="left: 400px" v-show="child1.length>0">
            <li v-for="item in child1" @click="go(item)">{{ item.name }}</li>
          </ul>
        </div>
        <div class="link">
          <span @click="gos(0)" :class="index==0?'active':''">京东馆</span>
          <span @click="gos(1)" :class="index==1?'active':''">自有商品</span>
        </div>
      </div>
    </div>
    <div class="po">
    </div>
    <en-table-layout
      toolbar pagination
      :tableData="pageData.data"
      @selection-change="handleSelectionChange"
      :loading="loading">
      <div slot="toolbar" class="inner-toolbar">
        <div class="toolbar-btns">
          <div class="conditions px">
            <!--<span :class="{ 'active' : params.eeeee == 'sssss', 'up': params.fffff == 'up', 'down': params.fffff == 'down' }" @click="px('sssss')">利润率</span>-->
            <span :class="{ 'active' : sort1 == 'price', 'up': sort2 == 'asc', 'down': sort2 == 'desc' }"
                  @click="pxx('price')">会员价</span>
            <span :class="{ 'active' : sort1 == 'buynum', 'up': sort2 == 'asc', 'down': sort2 == 'desc' }"
                  @click="pxx('buynum')">销量</span>
            <span :class="{ 'active' : sort1 == 'time', 'up': sort2 == 'asc', 'down': sort2 == 'desc' }"
                  @click="pxx('time')">上架时间</span>
          </div>
          <div class="conditions">
            <span>会员价：</span>
            <el-input style="width: 70px;" :controls="false" v-model="params.low_price"></el-input>
            <span style="margin: 0 5px;">-</span>
            <el-input style="width: 70px;" :controls="false" v-model="params.high_price"></el-input>
          </div>
          <!--<div class="conditions">
              <span>利润率%：</span>
              <el-input-number style="width: 70px;" :controls="false" v-model="params.dddd" :min="0"></el-input-number>
              <span style="margin: 0 5px;">-</span>
              <el-input-number style="width: 70px;" :controls="false" v-model="params.eeee" :min="0"></el-input-number>
          </div>-->
          <div class="conditions">
            <span>市场价：</span>
            <el-input style="width: 70px;" :controls="false" v-model="params.high_mktprice"></el-input>
            <span style="margin: 0 5px;">-</span>
            <el-input style="width: 70px;" :controls="false" v-model="params.high_mktprice"></el-input>
          </div>
          <el-button @click="GET_GoodsList" type="primary">筛选</el-button>
          <!-- <el-button @click="gotoRecycle" type="primary">回收站</el-button> -->
          <el-button @click="selectGoodsAll" type="primary">批量挑选</el-button>
          <!-- <el-button @click="underBatch" type="danger">批量下架</el-button> -->
        </div>
      </div>
      <template slot="table-columns">
        <el-table-column type="selection" width="55">
        </el-table-column>
        <el-table-column label="商品图片" width="120">
          <template slot-scope="scope">
            <!--            <router-link to="/markets/good">-->
            <!--            </router-link>-->
            <img :src="scope.row.thumbnail" class="goods-image" alt=""/>
          </template>
        </el-table-column>
        <el-table-column label="商品名称" :show-overflow-tooltip="true" width="220">
          <template slot-scope="scope">
            {{ scope.row.goods_name }}
          </template>
        </el-table-column>
        <el-table-column label="市场价" width="120">
          <template slot-scope="scope">
            <!-- {{ scope.row.price | unitPrice('￥') }} -->
            <span v-if="scope.row.goods_type == 'POINT'">
                            {{
                scope.row.exchange_money | unitPrice('￥')
              }} + {{ scope.row.exchange_point ? scope.row.exchange_point : 0 }} 积分
                        </span>
            <span v-else>
                            {{ scope.row.mktprice | unitPrice('￥') }}
                        </span>
          </template>
        </el-table-column>
        <el-table-column label="会员价" width="120">
          <template slot-scope="scope">
            <!-- {{ scope.row.price | unitPrice('￥') }} -->
            <span v-if="scope.row.goods_type == 'POINT'">
                            {{
                scope.row.exchange_money | unitPrice('￥')
              }} + {{ scope.row.exchange_point ? scope.row.exchange_point : 0 }} 积分
                        </span>
            <span v-else>
                            {{ scope.row.price | unitPrice('￥') }}
                        </span>
          </template>
        </el-table-column>
        <el-table-column label="库存" width="80">
          <template slot-scope="scope">{{ scope.row.enable_quantity }}</template>
        </el-table-column>
        <el-table-column label="销量" width="80">
          <template slot-scope="scope">{{ scope.row.buy_count }}</template>
        </el-table-column>
        <el-table-column label="操作" width="100" style="text-align: left;">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="selectGoods(scope.row)">挑选
            </el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination
        slot="pagination"
        @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange"
        :current-page="pageData.page_no"
        :page-size="pageData.page_size"
        :page-sizes="MixinPageSizes"
        :layout="MixinTableLayout"
        background
        :total="pageData.data_total"
      />
    </en-table-layout>
    <en-gaijia-picker
      :showm="dialogShopShow1"
      @close="dialogShopShow1 = false"
      @confirm1="handleShopPickerConfirm1"
      @res="res"
    />
    <en-store-picker :show="dialogShopShow" @close="dialogShopShow = false" @confirm="handleShopPickerConfirm"/>
  </div>
</template>
<script>
import * as API_goods from '@/api/goods'
import * as API_goodsCategory from '@/api/goodsCategory'
import {CategoryPicker} from '@/components'
import SvgIcon from '@/components/SvgIcon';
import EnTableLayout from '../../../../ui-components/TableLayout/src/main';
// import { RegExp } from '@/../ui-utils'
export default {
  name: 'goods',
  components: {
    EnTableLayout,
    SvgIcon,
    [CategoryPicker.name]: CategoryPicker
  },
  data() {
    return {
      pageData: {
        page_no: 1,
        page_size: 20,
        data_total: '',
        data: [],
      },
      child1: [],
      child: [],
      index: 1,
      dialogShopShow1: false,
      dialogShopShow: false,
      aaaaa: '',
      idsList: [],
      /** 列表loading状态 */
      loading: false,

      /** 列表参数 */
      sort1: '',
      sort2: '',
      params: {
        page_no: 1,
        page_size: 20,
        high_price: '',
        low_price: '',
        high_mktprice: '',
        low_mktprice: '',
        self_goods: 0,
        sort: '',
        category_path: '',
        keyword: '',
        goods_source: 0
      },

      /** 店铺信息 */
      shopInfo: this.$store.getters.shopInfo,
      ac: 1,
      categoryListLevel1: [],
      tempList: [],
      shop_id: '',
      pa: {
        set_under_message: ''
      }
    }
  },
  filters: {},
  activated() {
    this.GET_GoodsList()
  },
  mounted() {
    this.params.keyword = this.$route.query.keyWord || '';
    this.params.category_path = this.$route.query.path || '';

    this.index = this.$route.query.index || 0;
    this.GET_GoodsList()
    API_goodsCategory.getGoodsCategoryLevelList(0, {}).then((response) => {
      this.categoryListLevel1 = response;
      this.initType(this.categoryListLevel1)
    })
  },
  methods: {
    gos(ind) {
      if (ind == 0) {

      }
      if (ind == 1) {
        this.$router.push({
          path: '/markets/privateGoods',
          query: {
            index: ind
          }
        })
      }
    },
    hov(item, index) {
      // console.log(this.categoryListLevel1,"item");
      if (index == 0) {
        this.child = item.child;
      }
      if (index == 1) {
        this.child1 = item.child;
      }

    },
    initType(arr) {
      var arr = arr;
      for (let i = 0; i < arr.length; i++) {
        arr[i].child = [];
        API_goodsCategory.getGoodsCategoryLevelList(arr[i].category_id, {}).then((response) => {
          // console.log(i,this.categoryListLevel1);
          arr[i].child = response;
          if (response.length > 0) {
            this.initType(response);
          } else {
            return;
          }
        })
      }

    },
    go(item) {
      this.params.category_path = item.category_path;
      this.GET_GoodsList();
    },
    close() {
      this.dialogShopShow1 = false;
      console.log('改价已关闭')
    },
    addShop() {
      this.$router.push('/markets/publish')
    },
    res() {
      this.dialogShopShow = true;
      this.dialogShopShow1 = false
    },
    handleShopPickerConfirm1(item) {
      this.dialogShopShow1 = false;
      console.log('改价内容', item);
      this.pa.shop_price = item.shop_price;
      this.pa.enterprise_price = item.enterprise_price;
      this.pa.shop_goods_status = item.shop_goods_status;
      this.pa.goods_ids = this.getGoodsId();
      API_goods.sendShop(this.pa).then(res => {
        // console.log(pa)
        this.$message.success('改价成功！');
        this.GET_GoodsList()
      })
      // console.log(this.pa,"参数是");
    },
    activv(v) {
      this.ac = v
    },
    handleShopPickerConfirm(list) {
      if (!list.shop_id) {
        return this.$message.error('请选择商家');
      }
      this.dialogShopShow1 = true;
      this.shop_id = list.shop_id;
      this.pa.shop_id = list.shop_id;
    },
    getGoodsId() {
      let arr = [];
      for (let i = 0; i < this.idsList.length; i++) {
        arr.push(this.idsList[i].goods_id);
      }
      console.log(arr, 'id列表');
      return arr;
    },

    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.pageData.page_size = size
      this.GET_GoodsList()
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.pageData.page_no = page
      this.GET_GoodsList()
    },
    pxx(name) {
      console.log(name, 'name')
      if (this.sort1 == name) {
        this.sort2 = this.sort2 == 'asc' ? 'desc' : 'asc'
      } else {
        this.sort2 = 'asc'
      }
      this.sort1 = name;
      this.params.sort = this.sort1 + '_' + this.sort2;
      this.GET_GoodsList();
      console.log(this.sort1 + '_' + this.sort2, '当前排序')
    },
    /** 搜索事件触发 */
    searchEvent(data) {

      this.GET_GoodsList()
    },
    selectGoods(row) {
      this.idsList = [];
      this.idsList.push(row);
      this.dialogShopShow = true;
    },
    selectGoodsAll() {
      this.idsList = this.tempList;
      if (!this.idsList.length) {
        this.$message.error('请勾选商品');
        return;
      }
      this.dialogShopShow = true
    },
    /*selectGoods(){
      this.dialogShopShow = true
      if(!this.idsList.length){
        this.$message.error('请勾选商品')
      }else{
        this.$confirm('确认上架这些商品, 是否继续?', '提示', { type: 'warning' }).then(() => {
          API_goods.upBatchGoods(this.idsList.toString(), { goods_ids: this.idsList }).then(response => {
            this.dialogGoodsAuditVisible = false
            this.$message.success('上架成功！')
            this.GET_GoodsList()
          })
        })
      }
    },*/
    handleSelectionChange(list) {
      console.log(list);
      this.tempList = list;
    },
    searchGoods() {
      this.GET_GoodsList()
    },
    GET_GoodsList() {
      this.params.page_no = this.pageData.page_no
      this.params.page_size = this.pageData.page_size
      this.loading = true
      API_goods.getGoodsListq(this.params).then(response => {
        this.loading = false
        // console.log(response)
        this.pageData = response;
      })
    }

  }
}
</script>
<style lang="scss" scoped>
/deep/ .el-input-group--append .el-input__inner {
  border-top-left-radius: 0;
  padding-left: 28px;
}

/deep/ .el-input-group__append {
  background-color: rgba(26, 67, 169, 1);
  color: white;
}

.cx {
  z-index: 111;
  position: absolute;
  top: 13px;
  left: 8px;
  color: gray;
}

.mb {
  position: relative;
}

.ser {
  width: 400px;
  margin: auto;
}

.ser > div {
  text-align: left;
}

.ser .cser > div.active {
  background-color: rgba(26, 67, 169, 1);
  color: white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.ser .cser > div {
  display: inline-block;
  height: 40px;
  width: 100px;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  color: #1a43a9;
  cursor: pointer;
}

/deep/ div.toolbar {
  height: 80px;
  padding: 20px 0;
}


.inner-toolbar {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

/deep/ .pop-sku {
  .toolbar {
    display: none;
  }

  .el-dialog__body {
    .el-table {
      border: 1px solid #e5e5e5;
    }
  }

  .el-table__body-wrapper {
    max-height: 400px;
    overflow-y: scroll;
  }
}

.po {
  height: 5px;
  background-color: #f5f6f9;
}

.toolbar-search {
  margin-right: 10px;
  width: 20%;
}

div.toolbar-btns {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;

  div {
    span {
      display: inline-block;
      font-size: 14px;
      color: #606266;
    }
  }

  .conditions {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    min-width: 24.5%;

    .choose-machine {
      width: 60%;
    }
  }
}

.goods-image {
  width: 40px;
  height: 40px;
}

/deep/ div.cell {
  overflow: hidden;

  text-overflow: ellipsis;

  display: -webkit-box;

  -webkit-box-orient: vertical;

  -webkit-line-clamp: 2;
}

/*下架原因*/
.under-reason {
  color: red;
  cursor: pointer;
}

</style>
<style lang="scss" scoped>
.warp {
  background: #fff;
}

.search {
  padding-top: 20px;
  text-align: center;
}

.sInput {
  width: 400px;
  margin-bottom: 20px;
}

.searchBottom {
  text-align: left;
  padding-left: 20px;
}

.flBox {
  position: relative;
  display: inline-block;
}

.link {
  display: inline-block;
}

.link span {
  margin-left: 30px;
  cursor: pointer;
}

.link span.active {
  color: red;
}

.flBox .menu {
  width: 200px;
  height: 48px;
  line-height: 48px;
  background: rgba(26, 67, 169, 1);
  text-align: center;
  color: #fff;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.flBox ul {
  display: none;
  list-style: none;
  position: absolute;
  left: 0;
  width: 200px;
  top: 48px;
  background: rgba(0, 0, 0, 0.7);
  z-index: 2;
  margin: 0;
  padding: 0;
  padding: 10px 0;
  height: 300px;
  overflow: auto;
}

.flBox ul li {
  display: block;
  height: 35px;
  line-height: 35px;
  text-align: center;
  color: #fff;
}

.flBox ul li:hover {
  color: rgba(26, 67, 169, 1);
  cursor: pointer;
}

.flBox:hover ul {
  display: block;
}

.px {
  width: 300px;
}

.px span {
  padding-right: 12px;
  margin-right: 10px;
  background: url('../../../assets/ac1.png') no-repeat right center;
  -webkit-background-size: 8px auto;
  background-size: 8px auto;
  cursor: pointer;
}

.px span.active.up {
  background-image: url('../../../assets/ac2.png');
}

.px span.active.down {
  background-image: url('../../../assets/ac3.png');
}

div.toolbar-btns {
  display: block;
}

div.toolbar-btns .conditions {
  display: inline-block;
  width: auto;
  min-width: 0;
  margin-right: 30px;
}
</style>
